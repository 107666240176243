import React, { useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Select } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { CapsuleSelectionValue } from '@/reportEditor/report.constants';

export interface ReactSelectOption<T = string | number | CapsuleSelectionValue> {
  text: string;
  value: T;
  icon?: string;
  label?: string | React.ReactElement;
  divider?: boolean;
}

/**
 * Renders a Select input that may contain icons and plain text options.
 */
interface IconSelectProps {
  /** an array of options. The text property will be displayed as the text of the option. Add a label to the object
   *  and set formattedOptions=true to use a custom label. */
  selectOptions: readonly ReactSelectOption[];
  /** function that is called when an option is selected. The first param is an object with a value key, the second
   * param is optional. If a "name" is provided as part of the IconSelectProps the name is passed back as the
   * property via this function. */
  onChange: (option: ReactSelectOption, property?: string) => void;
  /** true if the 'selectOptions' will come in with custom labels, false to use
   * the default formatting here. If true, the selectOptions array must contain a 'label' attribute. */
  formattedOptions?: boolean;
  /** string that is provided to the onChange function as the second parameter */
  name?: string;
  /** additional classNames for Select element. This can be useful for targeting selects in system tests in addition to
   *  styling. */
  extraClassNames?: string;
  /** translation key for placeholder text */
  placeholder?: string;
  /** selected value */
  value?: any;
  id?: string;
  testId?: string;
  /** specify true if element should be disabled */
  disabled?: boolean;
  /** additional classes for parent element of Select */
  wrapperClasses?: string;
  skipMemo?: boolean;
  isMultipleSelect?: boolean;
  isSearchable?: boolean;
  small?: boolean;
  inputGroup?: 'left' | 'right' | undefined;
  /** defaults to true */
  appendToBody?: boolean;
  showError?: boolean;
  tooltip?: string;
  creatable?: boolean;
  isClearable?: boolean;
}

const IconSelectUnwrapped: React.FunctionComponent<IconSelectProps> = (props) => {
  const {
    name,
    value,
    selectOptions,
    formattedOptions,
    extraClassNames,
    testId,
    placeholder,
    onChange,
    appendToBody = true,
    disabled = false,
    wrapperClasses = '',
    isMultipleSelect = false,
    isSearchable = false,
    small,
    inputGroup,
    showError,
    tooltip,
    creatable = false,
    isClearable,
  } = props;

  const { t } = useTranslation();

  const inputValueOnBlur = useRef<ReactSelectOption>();

  const makeLabel = (label: string, icon: string | undefined) => {
    if (_.isUndefined(icon)) {
      return t(label);
    }
    return (
      <span>
        <Icon icon={icon} extraClassNames={classNames('p2', { mr5: !!label })} type="text" />
        {t(label)}
      </span>
    );
  };

  const options = formattedOptions
    ? selectOptions
    : _.map(selectOptions, (option) => ({
        ...option,
        label: makeLabel(option.text, option?.icon),
      }));

  const getSelectedValue = (selectedValue: string | { value: string } | null | undefined) =>
    _.find(options, {
      value:
        !_.isNil(selectedValue) && typeof selectedValue === 'object' && 'value' in selectedValue
          ? selectedValue.value
          : selectedValue,
    });

  const selectedValueForDisplay = isMultipleSelect
    ? _.map(value, (eachSelectedValue) => getSelectedValue(eachSelectedValue))
    : getSelectedValue(value);

  return (
    <div
      data-testid={testId || `${name}_filter`}
      className={wrapperClasses}
      onClick={(e) => e.stopPropagation()}
      data-qtip-text={tooltip ? t(tooltip) : undefined}>
      <Select
        extraClassNames={extraClassNames ?? ''}
        placeholder={_.isEmpty(placeholder) ? '' : t(placeholder as string)}
        // only null resets the select to show the placeholder
        value={_.isUndefined(selectedValueForDisplay) ? null : selectedValueForDisplay}
        options={options as any}
        isSearchable={isSearchable}
        onChange={(selectedOption) => onChange(selectedOption as ReactSelectOption, name)}
        isDisabled={disabled}
        isMulti={isMultipleSelect}
        small={small}
        inputGroup={inputGroup}
        menuPortalTarget={appendToBody ? document.body : undefined}
        showError={showError}
        creatable={creatable}
        isClearable={isClearable}
        onInputChange={(inputValue, action) => {
          if (inputValue) {
            inputValueOnBlur.current = { value: inputValue, text: inputValue, label: inputValue };
          }
          if (action.action === 'input-blur' && inputValueOnBlur.current) {
            onChange(inputValueOnBlur.current as ReactSelectOption, name);
          }
          if (action.action === 'menu-close') {
            inputValueOnBlur.current = undefined;
          }
        }}
      />
    </div>
  );
};

export const IconSelect = React.memo(
  IconSelectUnwrapped,
  (prev, next) =>
    !(
      prev.skipMemo ||
      prev.extraClassNames !== next.extraClassNames ||
      prev.disabled !== next.disabled ||
      prev.placeholder !== next.placeholder ||
      !_.isEqualWith(prev.selectOptions, next.selectOptions, (o1, o2) => !_.isEqual(o1.value, o2.value)) ||
      !_.isEqual(prev.value, next.value)
    ),
);
